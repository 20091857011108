var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("font-awesome-icon", {
    attrs: {
      icon:
        _vm.currentOrder === _vm.fieldName
          ? _vm.reverse
            ? "sort-down"
            : "sort-up"
          : "sort"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }